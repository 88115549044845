<div class="container">
  <div>
    <nz-form-item>
      <nz-select id="purpose" [ngModel]="activeKey" (ngModelChange)="onSelectChange($event)">
        <nz-option *ngFor="let key of availableKeys" [nzValue]="key" [nzLabel]="key"></nz-option>
      </nz-select>
    </nz-form-item>
  </div>
  <div class="manage-banners">
    <nz-carousel [nzEnableSwipe]="false" #carousel [nzEffect]="'scrollx'" (nzAfterChange)="onSlideChange($event)">
      <div nz-carousel-content *ngFor="let banner of banners">
        <img [src]='banner.image' />
      </div>
    </nz-carousel>

    <form class="manage-banner-form" nz-form [formGroup]="form" (ngSubmit)="submitForm()">
      <!-- IMAGE -->
      <nz-form-item>
        <nz-form-label nzRequired nzFor="image">Image</nz-form-label>
        <nz-form-control class="img-form-control">
          <input nz-input readonly formControlName="image" id="image" (click)="fileInput.click()" />
          <input #fileInput hidden type="file" accept="image/*" (change)="onFileChange($event)"/>
        </nz-form-control>
      </nz-form-item>

      <!-- DESTINATION URL -->
      <nz-form-item>
        <nz-form-label nzFor="destination-url">Redirect url</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="destinationUrl" id="destination-url" />
        </nz-form-control>
      </nz-form-item>

      <!-- Title -->
      <nz-form-item>
        <nz-form-label nzRequired nzFor="title">Title</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="title" id="title" />
        </nz-form-control>
      </nz-form-item>

      <!-- CONTENT -->
      <nz-form-item>
        <nz-form-label nzRequired nzFor="content">Content</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="content" id="content" />
        </nz-form-control>
      </nz-form-item>

      <!-- NAME -->
      <nz-form-item>
        <nz-form-label nzFor="name">Name</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="name" id="name" />
        </nz-form-control>
      </nz-form-item>

      <!-- BTN TEXT -->
      <nz-form-item>
        <nz-form-label nzFor="btn-text">Button text</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="btnText" id="btn-text" />
        </nz-form-control>
      </nz-form-item>

      <!-- LOCALE -->
      <nz-form-item>
        <nz-form-label nzFor="locale">Locale</nz-form-label>
        <nz-select formControlName="locale" id="locale">
          <nz-option [nzValue]="locales.EN" nzLabel="EN"></nz-option>
          <nz-option [nzValue]="locales.PT" nzLabel="PT"></nz-option>
        </nz-select>
      </nz-form-item>

      <!-- PLATFORM -->
      <nz-form-item>
        <nz-form-label nzFor="platform">Platform</nz-form-label>
        <nz-select formControlName="platform" id="platform">
          <nz-option *ngFor="let platform of platforms" [nzValue]="platform" [nzLabel]="platform"></nz-option>
        </nz-select>
      </nz-form-item>

      <!-- POSITION -->
      <nz-form-item>
        <nz-form-label nzFor="position">Banner position</nz-form-label>
        <nz-form-control>
          <nz-input-number id="position" formControlName="position" [nzSize]="'large'" [nzMin]="0" [nzStep]="1"></nz-input-number>
        </nz-form-control>
      </nz-form-item>

      <!-- OPEN NEW TAB -->
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="needToOpenNewTab" id="new-tab">
            <span>Need to open a new tab when redirect</span>
          </label>
        </nz-form-control>
      </nz-form-item>

      <!-- BUTTON SAVE -->
      <nz-form-item nz-row>
        <nz-form-control>
          <div class="manage-controls">
            <button nz-button nzType="primary" (click)="onAddNewBanner($event)">Create new banner</button>
            <button nz-button nzType="primary" [disabled]="!form.valid || !hasChanges">Save</button>
            <button nz-button nzType="primary" nzDanger [disabled]="!deleteAvailable" (click)="onDeleteBanner($event)">Delete</button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
